table.transactions {
  width: 100%;
  margin: auto;
}
table.transactions thead {
  background: var(--bs-tertiary-bg);
  white-space: nowrap;
  font-weight: 700;
  font-size: 12px;
}
table.transactions thead th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
table.transactions .hash,
table.transactions .wallet {
  overflow: hidden;
  text-overflow: ellipsis;
}
table.transactions .hash > *,
table.transactions .wallet > * {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
table.transactions :global .badge {
  padding: 0.5rem;
  border-radius: 4px;
}
table.transactions :global .badge.bg-bond {
  background: #2e7cf61a;
  color: #2e7cf6;
}
table.transactions :global .badge.bg-airdrop {
  background: #2e7cf61a;
  color: #2e7cf6;
}
table.transactions :global .badge.bg-bought {
  background: #16c35b1a;
  color: #16c35b;
}
table.transactions :global .badge.bg-sold {
  background: #24bcc61a;
  color: #24BDC7;
}
table.transactions :global .badge.bg-smrt4smrtr {
  background: #24bcc61a;
  color: #24BDC7;
}
table.transactions :global .badge.bg-pair {
  background: #ff99001a;
  color: #f90;
}
table.transactions :global .badge.bg-mint {
  background: #7373731a;
  color: #737373;
}
table.transactions :global .badge.bg-rewards {
  background: #7373731a;
  color: #737373;
}
table.transactions :global .badge.bg-masterchef {
  background: #7373731a;
  color: #737373;
}
table.transactions :global .badge.bg-burn {
  background: #7373731a;
  color: #737373;
}
@media (max-width: 991.98px) {
  table.transactions {
    font-size: 0.75rem;
    display: block;
  }
  table.transactions thead {
    display: block;
    font-size: 0.66rem;
    white-space: nowrap;
  }
  table.transactions thead tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  table.transactions thead tr th {
    border: none;
  }
  table.transactions thead tr th:not(:last-child):not(:first-child) {
    padding-bottom: 0;
  }
  table.transactions thead tr .block {
    order: 0;
    flex: 1;
  }
  table.transactions thead tr .hash {
    order: -1;
    min-width: 100%;
  }
  table.transactions thead tr .network,
table.transactions thead tr .tag,
table.transactions thead tr .invested,
table.transactions thead tr .sold {
    flex: 1;
  }
  table.transactions thead tr .wallet {
    min-width: 100%;
  }
  table.transactions tbody {
    display: block;
  }
  table.transactions tbody tr {
    display: flex;
    flex-wrap: wrap;
  }
  table.transactions tbody tr td {
    display: flex;
    align-items: center;
  }
  table.transactions tbody tr .block {
    order: 0;
    flex: 1;
  }
  table.transactions tbody tr .network {
    flex: 1;
  }
  table.transactions tbody tr .hash {
    order: -1;
    min-width: 100%;
  }
  table.transactions tbody tr .tag {
    flex: 1;
  }
  table.transactions tbody tr .invested {
    flex: 1;
  }
  table.transactions tbody tr .sold {
    flex: 1;
  }
  table.transactions tbody tr .wallet {
    min-width: 100%;
  }
  table.transactions tbody tr td:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0;
  }
}