.invested {
  background: #e9f4d3 !important;
}

.sold {
  background: #E8EEF9 !important;
}

.netInvested {
  background: #EAE9EF !important;
}

.holding {
  background: #E1E9EC !important;
}

.costBasis {
  background: #ECE1E3 !important;
}

[data-theme=dark] .invested, [data-theme=dark] .sold, [data-theme=dark] .netInvested, [data-theme=dark] .holding, [data-theme=dark] .costBasis {
  color: var(--bs-light);
}
[data-theme=dark] .invested {
  background: #88b62d !important;
}
[data-theme=dark] .sold {
  background: #3163be !important;
}
[data-theme=dark] .netInvested {
  background: #696388 !important;
}
[data-theme=dark] .holding {
  background: #597f8d !important;
}
[data-theme=dark] .costBasis {
  background: #8d5962 !important;
}

.summary :global .row > * {
  padding: 6px;
  margin: 0;
}
.summary :global .row > * .card {
  margin-left: 0;
  margin-right: 0;
}