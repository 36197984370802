@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600|Orbitron:800");

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/621d7190964b0d91669b99d7.621d71927d8a3a7e91bbaf1d.1BzTJhp.rcp.png");

//
// overrides

// $font-size-base: 16px;
$border-radius: 8px;
$btn-border-radius: 9999px;

$input-btn-padding-y: .75rem;
$input-btn-padding-x: 1.25rem;

$display-font-weight: 700;

$font-family-base: "Poppins", Helvetica;

$h6-font-size: 1.125rem;

$card-bg: var(--bs-body-bg);

// \overrides


@import "~bootstrap/scss/bootstrap";


//
// common

@include media-breakpoint-down(sm) {
    .container .card.full-width {
        margin-left: calc(-1*var(--bs-gutter-x, 0.5));
        padding-left: var(--bs-gutter-x, 0.5);
        margin-right: calc(-1*var(--bs-gutter-x, 0.5));
        padding-right: var(--bs-gutter-x, 0.5);
    }

    .container-fluid > .card {
        margin-left: calc(-0.5*var(--bs-gutter-x));
        margin-right: calc(-0.5*var(--bs-gutter-x));
    }
}

.with-button {
    display: flex;
    position: relative;

    .button-wrapper {
        background: none;
        border: none;
        z-index: 100;
        position: absolute;
        right: 0;
        padding: .75rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;;
    }
}

.btn-clear {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 50px;
    flex: 1;
    background-color: var(--bs-gray-100);
    text-decoration: none;
    border: none;
}

// \common
:root {
    --bs-green: #008a1e;
}

:root[data-theme="light"] {
    --bs-secondary-bg: rgba(247, 247, 247, 1);
    --bs-gray-100: #f1f2f3;
    --bs-gray-600: #6F7D95F5;
    --bs-gray-900: #183B56;
    --bs-light: #ffffffff;
}

.btn-clear {
    background-color: var(--bs-secondary-bg);
}

body {
    background-color: var(--bs-tertiary-bg);
}

.card {
    background-color: var(--bs-body-bg);
    // background-color: var(--bs-secondary-bg);
    border: none;
}


// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}