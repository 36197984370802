.header {
  background-color: var(--bs-light);
  border-bottom: 1px solid #e9ecef;
}
.header .logo {
  color: #183b56;
  font-family: "Orbitron", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  flex: 1;
}
.header .darkModeButton {
  padding: 12px;
  margin-right: -12px;
}
@media (max-width: 575.98px) {
  .header .logo {
    font-size: 24px;
    margin-right: 0;
  }
  .header .costBasisButton {
    padding: 12px;
  }
}

[data-theme=dark] .header {
  background-color: var(--bs-secondary-bg);
  border-bottom: 1px solid #343a40;
}
[data-theme=dark] .header .logo {
  color: #ffc107;
}