.jadeAVAX {
  background: #e9f4d3 !important;
}

.sjadeAVAX {
  background: #E8EEF9 !important;
}

.jadeBSC {
  background: #EAE9EF !important;
}

.sjadeBSC {
  background: #E1E9EC !important;
}

.d1v1Bond {
  background: #ECE1E3 !important;
}

.d1v2Bond {
  background: #E8EEF9 !important;
}

.d2v1Bond {
  background: #EAE9EF !important;
}

.totalJade {
  background: #E1E9EC !important;
}

[data-theme=dark] .jadeAVAX,
[data-theme=dark] .sjadeAVAX,
[data-theme=dark] .jadeBSC,
[data-theme=dark] .sjadeBSC,
[data-theme=dark] .d1v1Bond,
[data-theme=dark] .d1v2Bond,
[data-theme=dark] .d2v1Bond,
[data-theme=dark] .totalJade {
  color: var(--bs-light);
}
[data-theme=dark] .jadeAVAX {
  background: #88b62d !important;
}
[data-theme=dark] .sjadeAVAX {
  background: #3163be !important;
}
[data-theme=dark] .jadeBSC {
  background: #696388 !important;
}
[data-theme=dark] .sjadeBSC {
  background: #597f8d !important;
}
[data-theme=dark] .d1v1Bond {
  background: #3163be !important;
}
[data-theme=dark] .d1v2Bond {
  background: #696388 !important;
}
[data-theme=dark] .d2v1Bond {
  background: #597f8d !important;
}
[data-theme=dark] .totalJade {
  background: #88b62d !important;
}

.summary :global .row > * {
  padding: 6px;
  margin: 0;
}
.summary :global .row > * .card {
  margin-left: 0;
  margin-right: 0;
}